import { useMemo, useState } from 'react';
import { useSpring, animated } from 'react-spring';
import { useKeyPressEvent } from 'react-use';
import { useLazyQuery } from '@apollo/client';
import {
  DownOutlined,
  UpOutlined,
  SearchOutlined,
  SettingOutlined,
} from '@ant-design/icons';
import {
  Button,
  DatePicker,
  Flex,
  Form,
  Input,
  Tooltip,
  Typography,
} from 'antd';

import CustomerSearchResults from './CustomerSearchResults';
import {
  SidebarPortal,
  SidebarDivider,
  SidebarScrollContainer,
} from 'layouts/DashboardLayout/Sidebar';

import AgentSettingsModal from 'components/AgentSettingsModal/AgentSettingsModal';
import SectionMessage from 'components/SectionMessage/SectionMessage';
import SearchCriteria from 'components/SearchCriteria/SearchCriteria';
import useAnalytics from 'hooks/useAnalytics';
import useAgent from 'hooks/useAgent';
import useModalControls from 'hooks/useModalControls';
import useAgentSettings from 'hooks/useAgentSettings';
import { formatDateToSubmit } from 'utilities/datesAndTimes';
import { CUSTOMER_SEARCH } from 'queries/customerSearch';

import {
  ANALYTICS_EVENTS,
  CUSTOMER_SEARCH_FIELD,
  SIZE,
} from 'utilities/constants';

import { COLORS } from 'utilities/styles';

import {
  clearCustomerSearchResults,
  setSearchExecuted,
  setCustomerSearchCriteria,
  setCustomerSearchResults,
  useCustomerSearchState,
} from './customerSearchState';

const ExpandableFormField = ({ active, children, ...formItemProps }) => {
  const [showField, setShowField] = useState(active);
  const containerStyles = useSpring({
    minHeight: active ? '80px' : '0px',
    maxHeight: active ? '80px' : '0px',
    opacity: active ? '1.0' : '0.0',
    onProps: () => {
      active && setShowField(active);
    },
    onRest: () => {
      !active && setShowField(active);
    },
  });

  return (
    <animated.div style={containerStyles}>
      {showField ? (
        <Form.Item {...formItemProps}>{children}</Form.Item>
      ) : undefined}
    </animated.div>
  );
};

const CustomerSearchPage = () => {
  const agent = useAgent();
  const [agentSettings] = useAgentSettings();
  const settingsModal = useModalControls();
  const { trackEvent } = useAnalytics();
  const [searchForm] = Form.useForm();
  const [showAllFields, setShowAllFields] = useState(false);
  const [showEmptyFieldsMessage, setShowEmptyFieldsMessage] = useState(false);
  const { searchExecuted, searchCriteria, searchResults } =
    useCustomerSearchState();

  const defaultFields = useMemo(() => {
    return agentSettings.defaultCustomerSearchFields;
  }, [agentSettings]);

  const [searchCustomers, customerQuery] = useLazyQuery(CUSTOMER_SEARCH);

  const parseSearchCriteria = () => {
    const formValues = searchForm.getFieldsValue();
    const searchFields = {};
    Object.keys(formValues).forEach((key) => {
      let fieldValue = formValues[key];
      if (
        fieldValue !== '' &&
        fieldValue !== undefined &&
        fieldValue !== null
      ) {
        if (key !== CUSTOMER_SEARCH_FIELD.BIRTH_DATE.name) {
          fieldValue = fieldValue.trim();
        }
        if (key === CUSTOMER_SEARCH_FIELD.PHONE_NUMBER.name) {
          if (!fieldValue.startsWith('+1')) {
            fieldValue = '+1' + fieldValue;
          }
        }
        if (key === CUSTOMER_SEARCH_FIELD.BIRTH_DATE.name) {
          fieldValue = formatDateToSubmit(fieldValue);
        }
        if (key === CUSTOMER_SEARCH_FIELD.FULL_SSN.name) {
          fieldValue = fieldValue.replace(/\D/g, ''); // strip non-digit characters
        }
        if (key === CUSTOMER_SEARCH_FIELD.CARD_LAST_4.name) {
          fieldValue = fieldValue.replace(/\D/g, ''); // strip non-digit characters
        }
        searchFields[key] = fieldValue;
      }
    });
    if (!Object.values(searchFields).length) {
      throw new Error();
    }
    return searchFields;
  };

  const handleSubmit = async () => {
    let searchFields;
    setShowEmptyFieldsMessage(false);
    try {
      searchFields = parseSearchCriteria();
    } catch (error) {
      setShowEmptyFieldsMessage(true);
      return;
    }

    trackEvent(ANALYTICS_EVENTS.CUSTOMER_SEARCH_CLICKED, { searchFields });

    try {
      setSearchExecuted(true);
      setCustomerSearchCriteria(searchFields);
      clearCustomerSearchResults();
      const response = await searchCustomers({
        variables: { customerSearchRequestBody: searchFields },
      });
      const results = response?.data?.searchCustomer;
      setCustomerSearchResults(results);
    } catch (error) {
      console.log(error);
    }
  };
  useKeyPressEvent('Enter', handleSubmit);

  const onReset = () => {
    const fields = searchForm.getFieldsValue();
    searchForm.setFieldsValue(
      Object.keys(fields).forEach((name) => {
        searchForm.setFieldValue(name, '');
      }),
    );
  };

  const onRemoveCriteria = (name) => {
    searchForm.setFieldValue(name, '');
    handleSubmit();
  };

  const isFieldActive = (fieldName) => {
    return showAllFields || defaultFields.includes(fieldName);
  };

  const getFieldProps = (field) => {
    return {
      active: isFieldActive(field.name),
      ...field,
    };
  };

  const loading = customerQuery.loading;

  return (
    <>
      <AgentSettingsModal {...settingsModal} />

      <SidebarPortal>
        <Flex align="center" justify="space-between">
          <Typography.Title
            level={5}
            style={{
              margin: 0,
            }}
          >
            Customer Search
          </Typography.Title>

          <Tooltip title="Configure search fields">
            <Button
              data-testid="configure-search-fields"
              type="link"
              icon={<SettingOutlined style={{ fontSize: '1.2rem' }} />}
              onClick={settingsModal.show}
              style={{
                display: 'flex',
              }}
            ></Button>
          </Tooltip>
        </Flex>

        <SidebarDivider />

        <SidebarScrollContainer>
          <Form
            form={searchForm}
            layout="vertical"
            onFinish={handleSubmit}
            initialValues={{}}
          >
            <ExpandableFormField
              {...getFieldProps(CUSTOMER_SEARCH_FIELD.FIRST_NAME)}
            >
              <Input />
            </ExpandableFormField>

            <ExpandableFormField
              {...getFieldProps(CUSTOMER_SEARCH_FIELD.LAST_NAME)}
            >
              <Input />
            </ExpandableFormField>

            <ExpandableFormField
              {...getFieldProps(CUSTOMER_SEARCH_FIELD.LAST_4_SSN)}
            >
              <Input />
            </ExpandableFormField>

            {agent?.isSuperUser && (
              <ExpandableFormField
                {...getFieldProps(CUSTOMER_SEARCH_FIELD.FULL_SSN)}
              >
                <Input />
              </ExpandableFormField>
            )}

            <ExpandableFormField
              {...getFieldProps(CUSTOMER_SEARCH_FIELD.PHONE_NUMBER)}
            >
              <Input />
            </ExpandableFormField>

            <ExpandableFormField
              {...getFieldProps(CUSTOMER_SEARCH_FIELD.EMAIL)}
            >
              <Input />
            </ExpandableFormField>

            <ExpandableFormField
              {...getFieldProps(CUSTOMER_SEARCH_FIELD.BIRTH_DATE)}
            >
              <DatePicker
                placeholder="MM/DD/YYYY"
                format={'M/D/YYYY'}
                style={{ width: '100%' }}
              />
            </ExpandableFormField>

            <ExpandableFormField
              {...getFieldProps(CUSTOMER_SEARCH_FIELD.STREET_1)}
            >
              <Input />
            </ExpandableFormField>

            <ExpandableFormField
              {...getFieldProps(CUSTOMER_SEARCH_FIELD.STREET_2)}
            >
              <Input />
            </ExpandableFormField>

            <ExpandableFormField {...getFieldProps(CUSTOMER_SEARCH_FIELD.CITY)}>
              <Input />
            </ExpandableFormField>

            <ExpandableFormField
              {...getFieldProps(CUSTOMER_SEARCH_FIELD.STATE)}
            >
              <Input />
            </ExpandableFormField>

            <ExpandableFormField
              {...getFieldProps(CUSTOMER_SEARCH_FIELD.EXTERNAL_ACCOUNT_ID)}
            >
              <Input />
            </ExpandableFormField>

            <ExpandableFormField
              {...getFieldProps(CUSTOMER_SEARCH_FIELD.CUSTOMER_ID)}
            >
              <Input />
            </ExpandableFormField>

            <ExpandableFormField
              {...getFieldProps(CUSTOMER_SEARCH_FIELD.ACCOUNT_ID)}
            >
              <Input />
            </ExpandableFormField>

            <ExpandableFormField
              {...getFieldProps(CUSTOMER_SEARCH_FIELD.CARD_LAST_4)}
            >
              <Input />
            </ExpandableFormField>
          </Form>
        </SidebarScrollContainer>

        <div>
          <SidebarDivider />
          <Button
            type="link"
            style={{ paddingLeft: 0, marginBottom: 'var(--spacing-sm)' }}
            onClick={() => {
              setShowAllFields(!showAllFields);
            }}
          >
            {`Show ${showAllFields ? 'default' : 'all'} search fields`}
            {showAllFields ? <UpOutlined /> : <DownOutlined />}
          </Button>
          {showEmptyFieldsMessage && (
            <Typography.Paragraph type="danger">
              No search criteria provided.
            </Typography.Paragraph>
          )}
          <Flex gap="var(--spacing-sm)">
            <Button
              danger
              data-testid="resetButton"
              onClick={onReset}
              disabled={loading}
            >
              Clear Form
            </Button>
            <Button
              data-testid="searchButton"
              type="primary"
              onClick={handleSubmit}
              loading={loading}
            >
              Search
            </Button>
          </Flex>
        </div>
      </SidebarPortal>

      {searchExecuted && searchResults ? (
        <>
          <SearchCriteria
            criteria={searchCriteria}
            onRemoveCriteria={onRemoveCriteria}
          />
          <CustomerSearchResults
            searchResults={searchResults}
            loading={loading}
          />
        </>
      ) : (
        <SectionMessage
          height={'500px'}
          icon={SearchOutlined}
          text="Search Customers"
          size={SIZE.MD}
          body={
            <Typography.Paragraph
              style={{ color: COLORS.grey2 }}
              align="center"
            >
              Use the fields to search for customers. <br />
              Toggle "Show all fields" to view an expanded group of search
              fields. <br />
              Configure default search fields by clicking the{' '}
              {<SettingOutlined />} button.
            </Typography.Paragraph>
          }
        />
      )}
    </>
  );
};

export default CustomerSearchPage;
