import { useEffect } from 'react';
import { Redirect, Switch, useParams, useRouteMatch } from 'react-router-dom';
import { Col, Row } from 'antd';
import { useMutation } from '@apollo/client';

import ApplicationMenu from './ApplicationMenu';
import ApplicantMeta from './ApplicantMeta';

import {
  SidebarPortal,
  SidebarDivider,
  SidebarScrollContainer,
} from 'layouts/DashboardLayout/Sidebar';

import AutoEvents from 'components/AutoEvents/AutoEvents';
import Interactions from 'components/Interactions/Interactions';
import StickyNotes from 'components/StickyNotes/StickyNotes';

import EFACallVerificationPage from 'pages/EFACallVerificationPage/EFACallVerificationPage';
import EFAContactPullPage from 'pages/EFAContactPullPage/EFAContactPullPage';
import POIVerificationPage from 'pages/POIVerificationPage/POIVerificationPage';
import FraudCasesPage from 'pages/FraudCasesPage/FraudCasesPage';
import AttachmentsPage from 'pages/AttachmentsPage/AttachmentsPage';
import CreditApplicationPage from 'pages/CreditApplicationPage/CreditApplicationPage';

import useAgent from 'hooks/useAgent';
import useCustomerInfo from 'hooks/useCustomerInfo';

import { ADD_APPLICATION_VIEW } from 'mutations/notes';

import { SentryRoute } from 'services/sentry';

const ApplicationLayout = () => {
  const { applicationId } = useParams();
  const { path } = useRouteMatch();
  const { customerInfo } = useCustomerInfo();
  const agent = useAgent();

  const [addApplicationView] = useMutation(ADD_APPLICATION_VIEW);

  useEffect(() => {
    const agentId = agent?.user?.requester_id;
    const agentName = agent?.user?.name;
    if (agentId && agentName) {
      addApplicationView({
        variables: {
          applicationId,
          agentId: agent?.user?.requester_id,
          agentName: agent?.user?.name,
        },
      });
    }
  }, [agent, applicationId]);

  useEffect(() => {
    if (customerInfo && customerInfo.fullName) {
      document.title = customerInfo.fullName;
    } else {
      document.title = 'Mission Control Application';
    }
  }, [customerInfo]);

  return (
    <>
      <SidebarPortal>
        <SidebarScrollContainer>
          <ApplicantMeta />
          <SidebarDivider />
          <ApplicationMenu />
        </SidebarScrollContainer>
      </SidebarPortal>

      <StickyNotes />
      <Switch>
        <Redirect exact from={`${path}`} to={`${path}/application-overview`} />
        <SentryRoute
          path={`${path}/attachments`}
          exact
          component={AttachmentsPage}
        />
        <SentryRoute path={`${path}/fraud-cases`} component={FraudCasesPage} />
        <SentryRoute
          path={`${path}/efa-call-verification`}
          component={EFACallVerificationPage}
        />
        <SentryRoute
          path={`${path}/efa-contact-pull`}
          component={EFAContactPullPage}
        />
        <SentryRoute
          path={`${path}/poi-verification`}
          component={POIVerificationPage}
        />
        <SentryRoute>
          <CreditApplicationPage
            style={{ maxHeight: '600px', overflow: 'auto' }}
          />
          <Row
            gutter={[8, 8]}
            style={{
              marginTop: 'var(--spacing-xs)',
            }}
          >
            <Col span={12}>
              <Interactions />
            </Col>
            <Col span={12}>
              <AutoEvents />
            </Col>
          </Row>
        </SentryRoute>
      </Switch>
    </>
  );
};

export default ApplicationLayout;
