import { Redirect, Switch } from 'react-router-dom';
import { useRouteMatch } from 'react-router-dom';
import { Layout } from 'antd';
import styled from '@emotion/styled';
import isPropValid from '@emotion/is-prop-valid';

import ActionBar from 'components/ActionBar/ActionBar';
import NoteBox from 'components/NoteBox/NoteBox';
import ApplicationLayout from 'layouts/ApplicationLayout/ApplicationLayout';
import CustomerDirectLinkSearch from 'components/CustomerDirectLinkSearch/CustomerDirectLinkSearch';
import CustomerLayout from 'layouts/CustomerLayout/CustomerLayout';
import CustomerSearchPage from 'pages/CustomerSearchPage/CustomerSearchPage';
import ApplicationSearchPage from 'pages/ApplicationSearchPage/ApplicationSearchPage';
import FraudFilesSearchPage from 'pages/FraudFilesSearchPage/FraudFilesSearchPage';
import NotFoundPage from 'pages/NotFoundPage/NotFoundPage';

import useSidebar from 'hooks/useSidebar';
import { SentryRoute } from 'services/sentry';
import { COLORS } from 'utilities/styles';

import Header from './Header';
import Sidebar, {
  SidebarProvider,
  useSidebarPortalHasContent,
} from './Sidebar';

const StyledContent = styled(Layout.Content, {
  shouldForwardProp: isPropValid,
})(({ isSearchPage, leftSidebarActive, rightSidebarActive }) => {
  const rightMargin = isSearchPage
    ? 'calc(var(--meta-width) + var(--spacing-xs))'
    : 'var(--meta-width)';
  return {
    padding: 'var(--spacing-sm)',
    minHeight: 'calc(100vh - var(--header-height))',
    backgroundColor: isSearchPage ? COLORS.haze4 : undefined,
    transition: 'margin-right 0.2s',
    marginLeft: leftSidebarActive ? 'var(--meta-width)' : undefined,
    marginRight: rightSidebarActive ? rightMargin : undefined,
  };
});

const DashboardLayout = () => {
  const { sidebarOpen } = useSidebar();
  const sidebarPortalInUse = useSidebarPortalHasContent();

  // Temporary.
  // Account/application pages would need some layout changes befor things would look good.
  // so it's easiest to just keep the background grey and make limited changes.
  const searchMatch = useRouteMatch('/*-search');

  return (
    <SidebarProvider>
      <Layout
        hasSider={sidebarPortalInUse}
        style={{
          minHeight: 'calc(100vh)',
          paddingTop: 'calc(var(--header-height)',
        }}
      >
        <Header />
        <Sidebar />
        <StyledContent
          isSearchPage={!!searchMatch}
          lightBackground={!!searchMatch}
          leftSidebarActive={sidebarPortalInUse}
          rightSidebarActive={sidebarOpen}
        >
          <Switch>
            <Redirect exact from="/" to="/customers" />
            <Redirect exact from="/customers/" to="/customer-search" />
            <Redirect exact from="/applications/" to="/application-search" />

            <SentryRoute
              exact
              path="/customer-search"
              component={CustomerSearchPage}
            />

            <SentryRoute
              exact
              path="/application-search"
              component={ApplicationSearchPage}
            />

            <SentryRoute
              path="/fraud-files-search"
              exact
              component={FraudFilesSearchPage}
            />

            <SentryRoute
              path="/search-email"
              exact
              component={CustomerDirectLinkSearch}
            />
            <SentryRoute
              path="/applications/:applicationId"
              component={ApplicationLayout}
            />
            <SentryRoute
              path="/customers/:customerId"
              component={CustomerLayout}
            />

            <SentryRoute component={NotFoundPage} />
          </Switch>
        </StyledContent>
        <ActionBar></ActionBar>
        <NoteBox />
      </Layout>
    </SidebarProvider>
  );
};

export default DashboardLayout;
