import { useEffect } from 'react';
import { Button, Flex, Form, Input, Typography } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { useLazyQuery } from '@apollo/client';
import { useKeyPressEvent } from 'react-use';

import FraudFilesSearchResults from './FraudFilesSearchResults';
import SectionMessage from 'components/SectionMessage/SectionMessage';

import useAnalytics from 'hooks/useAnalytics';
import { ANALYTICS_EVENTS, SIZE } from 'utilities/constants';
import { COLORS } from 'utilities/styles';
import { FRAUD_FILE_SEARCH } from 'queries/fraudFileSearch';

import {
  SidebarPortal,
  SidebarDivider,
  SidebarScrollContainer,
} from 'layouts/DashboardLayout/Sidebar';

import {
  clearFraudFilesSearchResults,
  setSearchExecuted,
  setFraudFilesSearchResults,
  useFraudFilesSearchState,
} from './fraudFilesSearchState';

const FormItem = ({ label, name, rules }) => {
  return (
    <Form.Item label={label} name={name} className="form-item" rules={rules}>
      <Input name={name} />
    </Form.Item>
  );
};

const FraudFilesSearchPage = () => {
  const { trackEvent } = useAnalytics();
  const { searchExecuted, searchResults } = useFraudFilesSearchState();

  const [searchForm] = Form.useForm();

  const showEmptyFieldsMessage = '';

  const [searchFraudFiles, searchFraudFileQuery] = useLazyQuery(
    FRAUD_FILE_SEARCH,
    {
      onCompleted: ({ retrieveFraudDocs }) => {
        setFraudFilesSearchResults(retrieveFraudDocs);
      },
    },
  );

  useEffect(() => {
    document.title = 'Mission Control Search';
  }, []);

  const handleSubmit = async () => {
    let formValues;
    try {
      formValues = await searchForm.validateFields();
    } catch {
      return;
    }

    trackEvent(ANALYTICS_EVENTS.FDR_FILE_SEARCH_CLICKED);

    const searchValues = Object.fromEntries(
      Object.entries(formValues).filter(([_, value]) => !!value),
    );

    setSearchExecuted(true);
    clearFraudFilesSearchResults();
    searchFraudFiles({ variables: { searchRequest: searchValues } });
  };
  useKeyPressEvent('Enter', handleSubmit);

  const onReset = () => {
    searchForm.resetFields();
  };

  const loading = searchFraudFileQuery.loading;

  return (
    <>
      <SidebarPortal>
        <Flex vertical={true}>
          <Typography.Title
            level={5}
            style={{
              margin: 0,
            }}
          >
            Fraud Files Search
          </Typography.Title>
        </Flex>

        <SidebarDivider />

        <SidebarScrollContainer>
          <Form
            form={searchForm}
            layout="vertical"
            size="middle"
            onFinish={handleSubmit}
          >
            <FormItem
              label="First Name"
              name="firstName"
              type="text"
              rules={[{ required: true, message: 'First Name is required' }]}
            />
            <FormItem
              label="Last Name"
              name="lastName"
              type="text"
              rules={[{ required: true, message: 'Last Name is required' }]}
            />
            <FormItem label="Account ID" name="accountId" type="text" />
            <FormItem label="Card Last 4" name="panLast4" type="text" />
          </Form>
        </SidebarScrollContainer>

        <div>
          <SidebarDivider />
          {showEmptyFieldsMessage && (
            <Typography.Paragraph type="danger">
              No search criteria provided.
            </Typography.Paragraph>
          )}
          <Flex gap="var(--spacing-sm)">
            <Button danger onClick={onReset} data-testid="resetButton">
              Clear Form
            </Button>
            <Button
              type="primary"
              onClick={handleSubmit}
              data-testid="searchButton"
            >
              Search
            </Button>
          </Flex>
        </div>
      </SidebarPortal>

      {searchExecuted && searchResults ? (
        <FraudFilesSearchResults data={searchResults} loading={loading} />
      ) : (
        <SectionMessage
          height={'500px'}
          icon={SearchOutlined}
          text="Search Fraud Files"
          size={SIZE.MD}
          body={
            <Typography.Paragraph
              style={{ color: COLORS.grey2 }}
              align="center"
            >
              Use the fields to search for fraud files.
            </Typography.Paragraph>
          }
        />
      )}
    </>
  );
};

export default FraudFilesSearchPage;
