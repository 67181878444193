import { Link } from 'react-router-dom';

import SearchResultsTable from 'components/SearchResultsTable/SearchResultsTable';

const columns = [
  {
    title: 'First Name',
    dataIndex: 'firstName',
    key: 'firstName',
  },
  {
    title: 'Last Name',
    dataIndex: 'lastName',
    key: 'lastName',
  },
  {
    title: 'Email',
    dataIndex: 'email',
    key: 'email',
    render: (email, customer) => {
      if (email && customer) {
        return <Link to={`/customers/${customer.customerId}`}>{email}</Link>;
      }
    },
  },
  {
    title: 'Last Four SSN',
    dataIndex: 'ssn',
    key: 'ssn',
    align: 'right',
  },
  {
    title: 'State',
    dataIndex: 'state',
    key: 'state',
  },
  {
    title: 'Local Time',
    dataIndex: 'localTime',
    key: 'localTime',
  },
];

const CustomerSearchResults = ({ searchResults, loading }) => {
  return (
    <SearchResultsTable
      columns={columns}
      data={searchResults}
      loading={loading}
      emptyText="No customers found"
    />
  );
};

export default CustomerSearchResults;
