import { Link } from 'react-router-dom';

import SearchResultsTable from 'components/SearchResultsTable/SearchResultsTable';

const columns = [
  {
    title: 'Primary Applicant',
    dataIndex: 'primaryApplicantName',
    key: 'primaryApplicantName',
  },
  {
    title: 'Application ID',
    dataIndex: 'applicationId',
    key: 'applicationId',
    render: (applicationId) => {
      return <Link to={`/applications/${applicationId}`}>{applicationId}</Link>;
    },
  },
  {
    title: 'Type',
    dataIndex: 'type',
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
  },
  {
    title: 'Status Reason',
    dataIndex: 'statusReason',
    key: 'statusReason',
  },
];

const ApplicationSearchResults = ({ searchResults, loading }) => {
  return (
    <SearchResultsTable
      columns={columns}
      data={searchResults}
      loading={loading}
      emptyText="No applicants found"
    />
  );
};

export default ApplicationSearchResults;
