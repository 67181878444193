import FixedSider from 'components/FixedSider/FixedSider';
import SoftPhone from 'components/SoftPhone/SoftPhone';

import useSidebar from 'hooks/useSidebar';

//ActionBar isn't needed. This could just be a part of SoftPhone
const ActionBar = () => {
  const { sidebarOpen } = useSidebar();

  return (
    <FixedSider
      collapsed={!sidebarOpen}
      width="var(--soft-phone-width)"
      collapsedWidth="0px"
      align="right"
      style={{
        zIndex: 15,
        marginLeft: sidebarOpen ? 'var(--spacing-xs)' : '0px',
        marginTop: 'var(--spacing-xxs)',
      }}
    >
      <SoftPhone />
    </FixedSider>
  );
};

export default ActionBar;
