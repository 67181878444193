import { createContext } from 'react';
import { useLocalStorage } from 'react-use';

import { CUSTOMER_SEARCH_FIELD } from 'utilities/constants';

export const AgentSettingsContext = createContext(null);

const INITIAL_SETTINGS = Object.freeze({
  defaultCustomerSearchFields: [
    CUSTOMER_SEARCH_FIELD.FIRST_NAME.name,
    CUSTOMER_SEARCH_FIELD.LAST_NAME.name,
    CUSTOMER_SEARCH_FIELD.PHONE_NUMBER.name,
    CUSTOMER_SEARCH_FIELD.EMAIL.name,
    CUSTOMER_SEARCH_FIELD.LAST_4_SSN.name,
    CUSTOMER_SEARCH_FIELD.EXTERNAL_ACCOUNT_ID.name,
  ],
});

const AgentSettingsProvider = ({ children }) => {
  const settings = useLocalStorage('mc-settings', INITIAL_SETTINGS);

  return (
    <AgentSettingsContext.Provider value={settings}>
      {children}
    </AgentSettingsContext.Provider>
  );
};

export default AgentSettingsProvider;
