import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useFlags } from 'launchdarkly-react-client-sdk';
import {
  Alert,
  App,
  Button,
  Col,
  Descriptions,
  Flex,
  Row,
  Tooltip,
  Typography,
} from 'antd';
import { WarningOutlined } from '@ant-design/icons';
import { useQuery, useLazyQuery } from '@apollo/client';
import dayjs from 'dayjs';

import AutopayModal from './AutopayModal/AutopayModal';
import AccountTransferHistoryModal from './AccountTransferHistoryModal';
import ClipEligibilityModal from './ClipEligibilityModal';
import DigitalWalletModal from './DigitalWalletModal';
import EarlySpendBonus from './EarlySpendBonus';
import IntroAndFutureApr from './IntroAndFutureApr';
import OfferEligibilityModal from '../OfferEligibilityModal';
import PastDueHistoryModal from './PastDueHistoryModal';
import RewardsRate from './RewardsRate';
import Referrals from './Referrals';
import CreditProtectionDetails from './CreditProtectionDetails';

import QueryBoundary from 'components/QueryBoundary/QueryBoundary';
import useAccountQuery from 'hooks/useAccountQuery';
import useModalControls from 'hooks/useModalControls';
import {
  ACCOUNT_PRICING_DETAILS,
  ACCOUNT_REFERRALS,
  GET_ACCOUNT_REWARDS,
} from 'queries/account';
import { GET_CAG_BY_ACCOUNT_ID } from 'queries/cag';
import { RE_AGE_ELIGIBILITY } from 'queries/reage';
import { DIGITAL_WALLET_INFO_QUERY } from 'queries/wallet';

import { formatDollarsFromCents, minDueWarningHelper } from 'utilities/helpers';
import { formatDate, getDueDate } from 'utilities/datesAndTimes';
import {
  CARD_ISSUANCE_STATE_STATUS,
  SECURED_CARD_STATUS,
} from 'utilities/constants';
import { COLORS } from 'utilities/styles';

const { Item } = Descriptions;
const { Text } = Typography;

const getReAgeEligibilityText = (isEligible, amount) => {
  if (isEligible === true) {
    return amount ? `Eligible (${formatDollarsFromCents(amount)})` : 'Eligible';
  } else {
    return 'Ineligible';
  }
};

const DIGITAL_WALLET_STATUS = {
  ACTIVE: 'Active',
  INACTIVE: 'Inactive',
  NONE: 'No History',
  ERROR: 'Error',
};

const CreditDetailsSection = () => {
  const { showDigitalWallet, showSponsorBank } = useFlags();
  const { message } = App.useApp();
  const { accountId, customerId } = useParams();

  const [enrolledInAutopay, setEnrolledInAutopay] = useState(false);

  const [introAprDetails, setIntroAprDetails] = useState(null);
  const [pricingDetails, setPricingDetails] = useState(null);
  const [referrals, setReferrals] = useState(null);
  const [digitalWalletInfo, setDigitalWalletInfo] = useState(null);
  const [digitalWalletStatus, setDigitalWalletStatus] = useState('');

  const [reAge, setReAge] = useState();
  const [reAgeFailureMessage, setReAgeFailureMessage] = useState('');

  const [rewards, setRewards] = useState({});
  const [rewardsFailureMessage, setRewardsFailureMessage] = useState('');

  const [cagLink, setCagLink] = useState(null);
  const { showCreditProtection, showAonLink } = useFlags();
  const { accountQuery, account, simpleAccount } = useAccountQuery(
    customerId,
    accountId,
  );

  const autopayModal = useModalControls();
  const clipEligibilityModal = useModalControls();
  const pastDueHistoryModal = useModalControls();
  const reAgeEligibilityModal = useModalControls();
  const accountTransferHistoryModal = useModalControls();
  const digitalWalletModal = useModalControls();

  useQuery(GET_CAG_BY_ACCOUNT_ID, {
    variables: { accountId },
    onCompleted: (data) =>
      setCagLink(data?.bookedAccountsLatestCag?.documentLink),
    onError: () => {
      message.error('Failed to retrieve CAG. Refresh and try again.');
    },
  });

  useEffect(() => {
    if (digitalWalletInfo) {
      const digitalWalletStatus = resolveDigitalWalletStatus(
        digitalWalletInfo.status,
      );
      setDigitalWalletStatus(digitalWalletStatus);
    }
  }, [digitalWalletInfo]);

  const resolveDigitalWalletStatus = (status) => {
    const resolvedStatus = DIGITAL_WALLET_STATUS[status];
    return resolvedStatus || DIGITAL_WALLET_STATUS['ERROR'];
  };

  const [getDigitalWalletInfo, digitalWallletInfoQuery] = useLazyQuery(
    DIGITAL_WALLET_INFO_QUERY,
    {
      variables: { accountId, customerId },
      onCompleted: ({ account }) => {
        setDigitalWalletInfo(account?.digitalWallet?.info);
      },
      onError: () => {},
    },
  );

  useEffect(() => {
    if (showDigitalWallet) {
      getDigitalWalletInfo();
    }
  }, [showDigitalWallet]);

  // Rewards only available for rewards card type
  useQuery(GET_ACCOUNT_REWARDS, {
    variables: {
      customerId,
      accountId,
    },
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    onCompleted: (data) => {
      if (data?.account) {
        setRewards(data.account.rewards);
      }
    },
    onError: () => {
      message.error('Failed to retrieve rewards. Refresh and try again.');
      setRewardsFailureMessage('Some rewards details failed to load.');
    },
  });

  const pricingDetailsQuery = useQuery(ACCOUNT_PRICING_DETAILS, {
    variables: { customerId, accountId },
    onCompleted: (data) => {
      setPricingDetails(data?.account?.pricingDetails);
    },
  });

  useQuery(ACCOUNT_REFERRALS, {
    variables: { customerId, accountId },
    onCompleted: (data) => {
      setReferrals(data?.account?.offers?.referral?.data);
    },
    onError: () => {
      setReferrals(null);
    },
  });

  const { refetch: refetchReAge } = useQuery(RE_AGE_ELIGIBILITY, {
    variables: { customerId, accountId },
    onCompleted: (data) => {
      if (reAgeFailureMessage) {
        setReAgeFailureMessage('');
      }

      const reAgeData = data?.account?.offers?.eligibility?.find(
        (offer) => offer.offerType === 'REAGE',
      );
      if (reAgeData) {
        setReAge(reAgeData);
      }
    },
    onError: () =>
      setReAgeFailureMessage(
        'Re-age eligibility check failed. Refresh and try again.',
      ),
  });

  const refetchAccount = () => {
    if (accountId && customerId) {
      accountQuery.refetch();
      refetchReAge?.();
    } else {
      message.error('Account ID or Customer ID not provided.');
    }
  };

  useEffect(() => {
    const hasAutopay = account?.autopay?.length > 0;
    setEnrolledInAutopay(hasAutopay);
  }, [account]);

  useEffect(() => {
    if (pricingDetails) {
      const accountPricingDetails = {
        futureApr: pricingDetails.futureApr,
        futureAprStartDate: pricingDetails.futureAprStartDate,
        introAprLengthInMonths: pricingDetails.introAprLengthInMonths,
        __typename: 'Pricing',
      };
      checkForNullPromotionValues(accountPricingDetails);
    }
  }, [pricingDetails]);

  const checkForNullPromotionValues = (promotions) => {
    const promotionDetails = Object.assign({}, promotions);
    delete promotionDetails.__typename;
    const hasNoUsablePromotions = Object.values(promotionDetails).every(
      (value) => !value,
    );

    if (hasNoUsablePromotions) {
      return;
    }

    if ('futureApr' in promotionDetails) {
      return setIntroAprDetails(promotionDetails);
    }
  };

  const {
    shouldHighlightMinDue,
    minDueStyle,
    minDueFormatted,
    highMinDueWarningMessage,
  } = minDueWarningHelper(
    account?.paymentInfo?.minimumDue,
    account?.balanceInfo?.currentBalance,
  );

  const minDueDisplay = shouldHighlightMinDue ? (
    <Tooltip title={highMinDueWarningMessage}>
      <WarningOutlined
        data-testid="minDueWarningIcon"
        title={highMinDueWarningMessage}
      />
      {minDueFormatted}
    </Tooltip>
  ) : (
    minDueFormatted
  );

  const statementStartDate = dayjs(
    account?.paymentInfo?.lastStatementCloseDate,
  ).add(1, 'day');

  const formatNextFeeDate = (date) => {
    const nextFeeDate = date.split('-');
    nextFeeDate.pop();
    const [year, month] = nextFeeDate;
    return `${month}/${year}`;
  };

  const cardIsActivated =
    account?.cardDetails?.issuanceState ===
      CARD_ISSUANCE_STATE_STATUS.ACTIVATED ||
    account?.cardDetails?.issuanceState ===
      CARD_ISSUANCE_STATE_STATUS.PRE_EXPIRED;
  // PRE_EXPIRED means card is within 90 days of expiration, but still activated

  const creditProtectionInfo = account?.insuranceStatusInfo?.find(
    (insurance) => insurance.insuranceType === 'CREDIT_PROTECTION',
  );

  const creditProtectionEligibility = account?.insuranceEligibilityInfo?.find(
    (insurance) => insurance.insuranceType === 'CREDIT_PROTECTION',
  )?.eligible;

  return (
    <QueryBoundary
      errorMessage="Failed to load account details."
      query={{ ...accountQuery, refetch: refetchAccount }}
      loadingOverride={pricingDetailsQuery.loading}
    >
      <Row gutter={[8, 12]}>
        <Col span={24} xl={8}>
          <Typography.Title level={5}>Card Details</Typography.Title>
          <Descriptions bordered={true} size="small">
            <Item label="Last 4" span={3}>
              <Flex
                align="center"
                justify="space-between"
                gap="var(--spacing-xxs)"
                wrap="wrap"
              >
                <div>{account?.cardDetails?.last4}</div>
                <Button
                  type="link"
                  size="small"
                  onClick={accountTransferHistoryModal.show}
                  style={{ padding: 0 }}
                >
                  View History
                </Button>
              </Flex>
            </Item>
            <Item label="Opened Date" span={3}>
              {simpleAccount?.createdAt
                ? formatDate(simpleAccount.createdAt)
                : ''}
            </Item>
            <Item label="Card Expiration Date" span={3}>
              {formatDate(account?.cardDetails?.expirationDate)}
            </Item>
            <Item label="Card Activation Date" span={3}>
              {cardIsActivated
                ? formatDate(account?.cardDetails?.cardReceiptVerificationDate)
                : 'Not Activated'}
            </Item>
            <Item label="Last Reissue Date" span={3}>
              {formatDate(account?.cardDetails?.lastCardRequestDate)}
            </Item>
            <Item label="CAG" span={3}>
              {cagLink && (
                <a
                  style={{
                    color: COLORS.blue1,
                  }}
                  target="_blank"
                  rel="noreferrer"
                  href={cagLink}
                >
                  Click to Open
                </a>
              )}
            </Item>
            <Item label="Credit Limit" span={3}>
              {`${formatDollarsFromCents(pricingDetails?.creditLimit)}`}
            </Item>
            <Item label="Annual Fee" span={3}>
              {`${formatDollarsFromCents(pricingDetails?.annualFee)}`}
            </Item>

            {pricingDetails?.nextMembershipFeeDate && (
              <Item label="Next Fee Assessment" span={3}>
                {formatNextFeeDate(pricingDetails?.nextMembershipFeeDate)}
              </Item>
            )}

            <Item label="Cash Advance Limit" span={3}>
              {`${formatDollarsFromCents(
                pricingDetails?.cashAdvanceCreditLimit,
              )}`}
            </Item>

            {introAprDetails ? (
              <Item label="APR" span={3}>
                <IntroAndFutureApr
                  account={account}
                  introAprDetails={introAprDetails}
                  pricingDetails={IntroAndFutureApr}
                />
              </Item>
            ) : (
              <Item label="APR" span={3}>
                {pricingDetails?.purchaseApr}%{' '}
                {pricingDetails?.purchaseAprType?.toLowerCase()}
              </Item>
            )}
            <Item label="Cash Advance APR" span={3}>
              {pricingDetails?.cashAdvanceApr}%{' '}
              {pricingDetails?.cashAprType?.toLowerCase()}
            </Item>

            {rewards?.rewardsRate && (
              <Item label="Rewards Rate" span={3}>
                <RewardsRate rewards={rewards} />
              </Item>
            )}

            {rewards?.esbAmount && (
              <Item label="Early Spend Bonus" span={3}>
                <EarlySpendBonus rewards={rewards} />
              </Item>
            )}
            {showDigitalWallet && (
              <Item label="Digital Wallet" span={3}>
                <Flex
                  align="center"
                  justify="space-between"
                  gap="var(--spacing-xxs)"
                  wrap="wrap"
                >
                  <div data-testid="digital-wallet">{digitalWalletStatus}</div>
                  {digitalWalletStatus !== DIGITAL_WALLET_STATUS['ERROR'] ? (
                    <Button onClick={digitalWalletModal.show} type="link">
                      View Details
                    </Button>
                  ) : null}
                </Flex>
              </Item>
            )}
            {showSponsorBank && account?.cardDetails?.bank && (
              <Item label="Sponsor Bank" span={3}>
                <div data-testid="sponsor-bank">
                  {account?.cardDetails?.bank}
                </div>
              </Item>
            )}
          </Descriptions>

          {rewardsFailureMessage ? (
            <Alert
              data-testid="failure-message"
              type="error"
              message={rewardsFailureMessage}
              style={{ marginTop: 'var(--spacing-xs)' }}
            ></Alert>
          ) : null}
        </Col>
        <Col span={24} xl={8}>
          <Typography.Title level={5}>Balance & Due</Typography.Title>
          <Descriptions bordered={true} size="small">
            <Item label="Balance" span={3}>
              {`${formatDollarsFromCents(
                account?.balanceInfo?.currentBalance,
              )}`}
            </Item>
            <Item label="Available" span={3}>
              {`${formatDollarsFromCents(
                account?.balanceInfo?.availableCredit,
              )}`}
            </Item>
            <Item label="Due Date" span={3}>
              {getDueDate(account?.paymentInfo)}
            </Item>
            <Item style={minDueStyle} label="Total Min Due" span={3}>
              {minDueDisplay}
            </Item>
            <Item label="Statement Period" span={3}>
              {formatDate(statementStartDate)} -{' '}
              {formatDate(account?.paymentInfo?.nextStatementCloseDate)}
            </Item>
            <Item label="Past Due Amount" span={3}>
              <Flex
                align="center"
                justify="space-between"
                wrap="wrap"
                gap="var(--spacing-xxs)"
              >
                <div>{`${formatDollarsFromCents(
                  account?.balanceInfo?.pastDueBalance,
                )}`}</div>
                <Button
                  type="link"
                  size="small"
                  onClick={pastDueHistoryModal.show}
                  style={{
                    padding: 0,
                  }}
                >
                  View History
                </Button>
              </Flex>
            </Item>
            <Item label="Available Cash Advance" span={3}>
              {`${formatDollarsFromCents(
                account?.balanceInfo?.cashAdvanceAvailableCreditLimit,
              )}`}
            </Item>
            {account?.securedCardInfo && (
              <>
                <Item label="Current Secured Deposit Amount" span={3}>
                  {`${formatDollarsFromCents(
                    account.securedCardInfo.currentAmount,
                  )}`}
                </Item>
                <Item label="Original Secured Deposit Amount" span={3}>
                  {`${formatDollarsFromCents(
                    account.securedCardInfo.originalAmount,
                  )}`}
                </Item>
                <Item label="Security Deposit Status" span={3}>
                  {SECURED_CARD_STATUS[account.securedCardInfo.status]}
                </Item>
                <Item label="Date of Security Deposit Change" span={3}>
                  {account.securedCardInfo.refundedDate
                    ? formatDate(account.securedCardInfo.refundedDate)
                    : 'N/A'}
                </Item>
              </>
            )}
          </Descriptions>
        </Col>
        <Col span={24} xl={8}>
          <Typography.Title level={5}>Settings & Actions</Typography.Title>
          <Descriptions bordered={true} size="small">
            <Item label="Internal Autopay" span={3}>
              <Flex
                align="center"
                justify="space-between"
                wrap="wrap"
                gap="var(--spacing-xxs)"
              >
                <div>{enrolledInAutopay ? 'Enrolled' : 'Not Enrolled'}</div>
                <Button
                  type="link"
                  size="small"
                  onClick={autopayModal.show}
                  style={{ padding: 0 }}
                >
                  {enrolledInAutopay ? 'Manage' : 'Enroll'}
                </Button>
              </Flex>
            </Item>
            <Item label="Re-Age Eligible?" span={3}>
              {reAgeFailureMessage ? (
                <Text type="danger">Failed to fetch</Text>
              ) : (
                <Flex
                  align="center"
                  justify="space-between"
                  wrap="wrap"
                  gap="var(--spacing-xxs)"
                >
                  <div>
                    {getReAgeEligibilityText(
                      reAge?.eligible,
                      reAge?.data?.amountToPay,
                    )}
                  </div>
                  <Button
                    type="link"
                    size="small"
                    onClick={reAgeEligibilityModal.show}
                    style={{ padding: 0 }}
                  >
                    View Details
                  </Button>
                </Flex>
              )}
            </Item>
            {referrals && (
              <Item label="Referral Program" span={3}>
                <Referrals referrals={referrals} />
              </Item>
            )}
            <Item label="CLIP Eligibility Criteria" span={3}>
              <Button
                type="link"
                size="small"
                onClick={clipEligibilityModal.show}
                style={{
                  padding: 0,
                }}
              >
                View Criteria
              </Button>
            </Item>

            <Item label="Hardship Eligibility" span={3}>
              <a
                target="hardship_lookup"
                rel="noreferrer"
                href="https://tableau.missionlane.com/#/views/HardshipDashboardGlobal/GLOBALHARDSHIPDASHBOARD2"
              >
                View Eligibility
              </a>
            </Item>
            <Item label="Due Date Change Eligibility" span={3}>
              <a
                target="due_date_lookup"
                rel="noreferrer"
                href="https://tableau.missionlane.com/#/views/AgentsDueDateTool/DDChange"
              >
                View Eligibility
              </a>
            </Item>

            {showCreditProtection ? (
              <Item
                label={
                  <>
                    Credit Protection™
                    <br />
                    1-888-276-6443
                    {showAonLink && (
                      <>
                        <br />
                        <a
                          href="https://creditprotection.missionlane.com/"
                          target="_blank"
                          rel="noreferrer"
                        >
                          AON Service Portal
                        </a>
                      </>
                    )}
                  </>
                }
                span={3}
              >
                {
                  <div data-testid="credit-protection">
                    <CreditProtectionDetails
                      status={creditProtectionInfo?.status}
                      policyInfo={creditProtectionInfo?.policyInfo}
                      creditProtectionEligibility={creditProtectionEligibility}
                    />
                  </div>
                }
              </Item>
            ) : null}
          </Descriptions>
          {reAgeFailureMessage && (
            <Alert
              type="error"
              message={reAgeFailureMessage}
              style={{ marginTop: 'var(--spacing-xs)' }}
            />
          )}
          <AutopayModal {...autopayModal} />
          <ClipEligibilityModal {...clipEligibilityModal} />
          <PastDueHistoryModal
            {...pastDueHistoryModal}
            pastDue={account?.pastDue}
          />
          <AccountTransferHistoryModal {...accountTransferHistoryModal} />
          <OfferEligibilityModal
            {...reAgeEligibilityModal}
            offer="Re-Age"
            reAgeAmount={reAge?.data?.amountToPay}
            eligible={reAge?.eligible}
            eligibilityChecks={reAge?.checks}
          />
          <DigitalWalletModal
            {...digitalWalletModal}
            walletReferenceIds={digitalWalletInfo?.walletReferenceIds}
          />
        </Col>
      </Row>
    </QueryBoundary>
  );
};

export default CreditDetailsSection;
