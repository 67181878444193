import { createContext, useContext, useEffect, useState } from 'react';
import { makeVar, useReactiveVar } from '@apollo/client';
import { createPortal } from 'react-dom';
import styled from '@emotion/styled';
import { Divider, Flex, Layout } from 'antd';

import { COLORS } from 'utilities/styles';

export const SidebarDivider = styled(Divider)`
  margin: var(--spacing-sm) 0;
`;

export const SidebarScrollContainer = ({ children }) => {
  return (
    <Flex
      style={{
        minHeight: '0px',
      }}
    >
      <div style={{ flex: 1, overflowY: 'scroll' }}>{children}</div>
    </Flex>
  );
};

//Consider padding adjustments?
const StyledSidebar = styled(Layout.Sider)`
  &.mc-sidebar {
    position: fixed;
    background-color: ${COLORS.haze4};
    height: calc(100vh - var(--header-height));
    display: flex;
    flex-direction: column;
    padding: var(--spacing-md);
    padding-left: var(--spacing-lg);
    border-right: 1px solid var(--border-light);
  }
`;

const SidebarContext = createContext([]);
const sidebarPortalVar = makeVar(false);

export const useSidebarPortalHasContent = () => {
  return useReactiveVar(sidebarPortalVar);
};

export const SidebarProvider = (props) => {
  const sidebarState = useState(null);
  return <SidebarContext.Provider value={sidebarState} {...props} />;
};

const Sidebar = () => {
  const [, setSidebarContainer] = useContext(SidebarContext);
  const sidebarPortalHasContent = useSidebarPortalHasContent(sidebarPortalVar);
  return sidebarPortalHasContent ? (
    <StyledSidebar className="mc-sidebar" width="var(--meta-width)">
      <Flex
        ref={setSidebarContainer}
        vertical={true}
        style={{ height: '100%' }}
      />
    </StyledSidebar>
  ) : null;
};

export const SidebarPortal = ({ children }) => {
  const [sideBarContainer] = useContext(SidebarContext);

  useEffect(() => {
    sidebarPortalVar(true);
    return () => {
      sidebarPortalVar(false);
    };
  }, []);

  return sideBarContainer ? createPortal(children, sideBarContainer) : null;
};

export default Sidebar;
