import React from 'react';
import { Typography } from 'antd';
import { formatDate } from 'utilities/datesAndTimes';

const { Text } = Typography;

export const CREDIT_PROTECTION_STATUS = {
  ENROLLED: { raw: 'ENROLLED', text: 'Enrolled' },
  NOT_ENROLLED: { raw: 'NOT_ENROLLED', text: 'Not Enrolled' },
  PENDING: { raw: 'PENDING', text: 'Pending' },
  SUSPENDED: { raw: 'SUSPENDED', text: 'Suspended' },
  CANCELLED: { raw: 'CANCELLED', text: 'Cancelled' },
  NOT_DETERMINED: { raw: 'NOT_DETERMINED', text: 'Not Determined' },
};

const CreditProtectionDetails = ({ status, policyInfo, eligibility }) => {
  const creditProtectionStatusText = `${CREDIT_PROTECTION_STATUS[status]?.text}`;
  const dateEnrolledText = `Date Enrolled: ${formatDate(policyInfo?.dateEnrolled)}`;
  const dateStatusEffectiveText = `Date Status Effective: ${formatDate(policyInfo?.dateStatusEffective)}`;

  if (status === CREDIT_PROTECTION_STATUS.NOT_ENROLLED.raw) {
    return `${creditProtectionStatusText} - ${eligibility ? 'Eligible' : 'Ineligible'}`;
  }

  const PREVIOUSLY_ENROLLED_STATUS = [
    CREDIT_PROTECTION_STATUS.CANCELLED.raw,
    CREDIT_PROTECTION_STATUS.SUSPENDED.raw,
    CREDIT_PROTECTION_STATUS.PENDING.raw,
  ];

  if (PREVIOUSLY_ENROLLED_STATUS.includes(status)) {
    return (
      <>
        <Text strong>{creditProtectionStatusText}</Text>
        <br />
        {dateEnrolledText}
        <br />
        {dateStatusEffectiveText}
      </>
    );
  }

  if (status === CREDIT_PROTECTION_STATUS.ENROLLED.raw) {
    return (
      <>
        <Text strong>{creditProtectionStatusText}</Text>
        <br />
        {dateEnrolledText}
      </>
    );
  }
};

export default CreditProtectionDetails;
