import { createContext, useEffect, useMemo, useState } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { makeVar, useReactiveVar, useLazyQuery } from '@apollo/client';

import { APPLICANT_META } from 'queries/applicant';
import { CUSTOMER_OVERVIEW } from 'queries/customer';

import { CUSTOMER_TYPE } from 'utilities/constants';

const RECENTLY_VIEWED_KEY = 'mc-recently-viewed';
const RECENT_CUSTOMERS_KEY = 'customers';
const RECENT_APPLICATIONS_KEY = 'applications';

export class CustomerInfo {
  constructor(customerType = CUSTOMER_TYPE.CUSTOMER, info) {
    this.initialized = !!info;

    info = info || {};
    this.customerType = customerType;
    this.id = info.id || info.applicantId;
    this.accounts = info.accounts || [];
    this.firstName = info.contactInfo?.firstName || info.firstName;
    this.middleName = info.contactInfo?.middleName || info.middleName;
    this.lastName = info.contactInfo?.lastName || info.lastName;
    this.lastFourSSN = info.lastFourSsn || info.last4Ssn;
    this.datOfBirth = info.birthDate || info.dateOfBirth;
    this.email = info.contactInfo?.email || info.contactDetails?.emailAddress;
    this.phoneNumber =
      info.contactInfo?.rawPhoneNumber || info.contactDetails?.phoneNumber;

    this._fullName = info;
    this._address = info;
  }

  set _fullName(info) {
    if (info?.contactInfo) {
      info = info.contactInfo;
    }

    const firstName = info.firstName || '';
    const lastName = info.lastName || '';

    this.fullName = `${firstName} ${lastName}`.trim();
  }

  set _address(info) {
    const address =
      info?.contactInfo?.address || info?.contactDetails?.address || {};
    this.address = {
      city: address.city || '',
      state: address.state || '',
      street1: address.address1 || address.street1 || '',
      street2: address.address2 || address.street2 || '',
      postalCode: address.postalCode || address.zip || '',
    };
  }
}

const recentlyViewedStorage = localStorage.getItem(RECENTLY_VIEWED_KEY);

const recentlyViewedVar = makeVar(
  recentlyViewedStorage
    ? JSON.parse(recentlyViewedStorage)
    : {
        [RECENT_CUSTOMERS_KEY]: [],
        [RECENT_APPLICATIONS_KEY]: [],
      },
);

const updateRecentlyViewed = async (key, id, label) => {
  const recentlyViewed = recentlyViewedVar();
  let viewedSet = recentlyViewed[key];

  const currentIndex = viewedSet.findIndex((rc) => rc.id === id);

  if (currentIndex === 0) {
    return;
  }

  if (currentIndex > 0) {
    viewedSet = [
      viewedSet[currentIndex],
      ...viewedSet.slice(0, currentIndex),
      ...viewedSet.slice(currentIndex + 1),
    ];
  } else {
    viewedSet.unshift({
      id,
      label,
    });
  }

  if (viewedSet.length > 5) {
    viewedSet.pop();
  }

  const update = {
    ...recentlyViewed,
    [key]: viewedSet,
  };

  recentlyViewedVar(update);
  localStorage.setItem(RECENTLY_VIEWED_KEY, JSON.stringify(update));
};

export const CustomerInfoContext = createContext(null);

const CustomerInfoProvider = ({ children }) => {
  const [customerInfo, setCustomerInfo] = useState(new CustomerInfo());
  const [activeCustomerType, setActiveCustomerType] = useState();
  const recentlyViewed = useReactiveVar(recentlyViewedVar);

  // The following "route matches" track relevant path parameters
  // for use by components outside of the router context (where react-router useParams does not work)
  const customerRoute = useRouteMatch('/customers/:customerId');
  const customerAccountRoute = useRouteMatch(
    '/customers/:customerId/accounts/credit/:accountId',
  );
  const applicationRoute = useRouteMatch('/applications/:applicationId');

  const [customerId, accountId, applicationId] = useMemo(() => {
    const customerId = customerRoute?.params.customerId;
    const accountId = customerAccountRoute?.params.accountId;
    const applicationId = applicationRoute?.params.applicationId;
    return [customerId, accountId, applicationId];
  }, [customerRoute, customerAccountRoute, applicationRoute]);

  const [getApplicantMeta, applicantMetaQuery] = useLazyQuery(APPLICANT_META, {
    notifyOnNetworkStatusChange: true,
    onCompleted: ({ applicationV2 }) => {
      const applicants = applicationV2?.submission?.applicants || [];
      const applicant = applicants[0];
      const applicantInfo = new CustomerInfo(
        CUSTOMER_TYPE.APPLICANT,
        applicant,
      );
      const applicationId = applicationRoute?.params?.applicationId;
      if (applicationId) {
        updateRecentlyViewed(
          RECENT_APPLICATIONS_KEY,
          applicationId,
          applicantInfo.fullName,
        );
      }
      setCustomerInfo(applicantInfo);
    },
    onError: (error) => {
      console.log('ERROR', error);
    },
  });

  const [getCustomerMeta, customerMetaQuery] = useLazyQuery(CUSTOMER_OVERVIEW, {
    notifyOnNetworkStatusChange: true,
    onCompleted: (data) => {
      const customerInfo = new CustomerInfo(
        CUSTOMER_TYPE.CUSTOMER,
        data?.customer,
      );
      updateRecentlyViewed(
        RECENT_CUSTOMERS_KEY,
        customerInfo.id,
        customerInfo.fullName,
      );
      setCustomerInfo(customerInfo);
    },
    onError: (error) => {
      console.log('ERROR', error);
    },
  });

  useEffect(() => {
    if (applicationId) {
      setCustomerInfo(new CustomerInfo(CUSTOMER_TYPE.APPLICANT));
      getApplicantMeta({ variables: { applicationId } });
      setActiveCustomerType(CUSTOMER_TYPE.APPLICANT);
    }
  }, [applicationId]);

  useEffect(() => {
    if (customerId) {
      setCustomerInfo(new CustomerInfo(CUSTOMER_TYPE.CUSTOMER));
      getCustomerMeta({ variables: { customerId } });
      setActiveCustomerType(CUSTOMER_TYPE.CUSTOMER);
    }
  }, [customerId]);

  return (
    <CustomerInfoContext.Provider
      value={{
        accountId,
        applicationId,
        customerId,
        customerInfo,
        customerInfoQuery:
          activeCustomerType === CUSTOMER_TYPE.APPLICANT
            ? applicantMetaQuery
            : customerMetaQuery,
        recentApplications: recentlyViewed.applications,
        recentCustomers: recentlyViewed.customers,
      }}
    >
      {children}
    </CustomerInfoContext.Provider>
  );
};

export default CustomerInfoProvider;
