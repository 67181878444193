import { useReactiveVar } from '@apollo/client';

import { createReactiveState } from 'utilities/stateHelpers';
import { CUSTOMER_TYPE } from 'utilities/constants';

const convertApplicationToSearchResult = (application) => {
  const submission = application?.submission;
  if (submission.applicants && submission.applicants.length) {
    const applicant = submission.applicants[0];
    return {
      type: CUSTOMER_TYPE.APPLICATION,
      key: application.applicationId,
      applicationId: application.applicationId,
      status: application.status,
      statusReason: application.statusReason,
      primaryApplicantName: `${applicant.firstName} ${applicant.lastName}`,
      primaryApplicantId:
        applicant.applicantType === 'PRIMARY' && applicant.applicantId
          ? applicant.applicantId
          : undefined,
    };
  }
};

const applicationSearchState = createReactiveState(() => {
  return {
    initialFieldValues: {},
    searchExecuted: false,
    searchCriteria: {},
    searchResults: [],
  };
});

export const setSearchExecuted =
  applicationSearchState.createPropertySetter('searchExecuted');

export const setApplicationSearchCriteria =
  applicationSearchState.createPropertySetter('searchCriteria');

export const setApplicationSearchResults = (results) => {
  const mappedResults = results.reduce((applicationList, application) => {
    applicationList.push(convertApplicationToSearchResult(application));
    return applicationList;
  }, []);
  applicationSearchState.setPropertyValue('searchResults', mappedResults);
};

export const clearApplicationSearchResults = () => {
  applicationSearchState.setPropertyValue('searchResults', []);
};

export const useApplicationSearchState = () => {
  return useReactiveVar(applicationSearchState.stateVar);
};

export default applicationSearchState;
