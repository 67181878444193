import { useEffect } from 'react';
import { Redirect, Switch, useParams, useRouteMatch } from 'react-router-dom';
import { Col, Row } from 'antd';
import { useMutation } from '@apollo/client';

import CustomerMenu from './CustomerMenu';
import CustomerMeta from './CustomerMeta/CustomerMeta';
import {
  SidebarPortal,
  SidebarDivider,
  SidebarScrollContainer,
} from 'layouts/DashboardLayout/Sidebar';

import AutoEvents from 'components/AutoEvents/AutoEvents';
import Interactions from 'components/Interactions/Interactions';
import StickyNotes from 'components/StickyNotes/StickyNotes';

import AttachmentsPage from 'pages/AttachmentsPage/AttachmentsPage';
import CreditAccountPage from 'pages/CreditAccountPage/CreditAccountPage';
import CreditApplicationPage from 'pages/CreditApplicationPage/CreditApplicationPage';
import CustomerDetailsPage from 'pages/CustomerDetailsPage/CustomerDetailsPage';
import CustomerOverviewPage from 'pages/CustomerOverviewPage/CustomerOverviewPage';
import FraudCasesPage from 'pages/FraudCasesPage/FraudCasesPage';
import FundingAccountsPage from 'pages/FundingAccountsPage/FundingAccountsPage';
import PreferencesPage from 'pages/PreferencesPage/PreferencesPage';

import useAgent from 'hooks/useAgent';
import useCustomerInfo from 'hooks/useCustomerInfo';

import { ADD_CUSTOMER_VIEW } from 'mutations/notes';

import { SentryRoute } from 'services/sentry';

const CustomerLayout = () => {
  const { customerId } = useParams();
  const { path } = useRouteMatch();
  const { customerInfo } = useCustomerInfo();
  const agent = useAgent();

  const [addCustomerView] = useMutation(ADD_CUSTOMER_VIEW);

  useEffect(() => {
    const agentId = agent?.user?.requester_id;
    const agentName = agent?.user?.name;
    if (agentId && agentName) {
      addCustomerView({
        variables: {
          customerId,
          agentId: agent?.user?.requester_id,
          agentName: agent?.user?.name,
        },
      });
    }
  }, [agent, customerId]);

  useEffect(() => {
    if (customerInfo && customerInfo.fullName) {
      document.title = customerInfo.fullName;
    } else {
      document.title = 'Mission Control Customer';
    }
  }, [customerInfo]);

  return (
    <>
      <SidebarPortal>
        <SidebarScrollContainer>
          <CustomerMeta />
          <SidebarDivider />
          <CustomerMenu />
        </SidebarScrollContainer>
      </SidebarPortal>

      <StickyNotes />
      <Switch>
        <Redirect exact from={`${path}`} to={`${path}/accounts-overview`} />
        <SentryRoute
          path={`${path}/details`}
          exact
          component={CustomerDetailsPage}
        />
        <SentryRoute
          path={`${path}/attachments`}
          exact
          component={AttachmentsPage}
        />
        <SentryRoute
          path={`${path}/funding-accounts`}
          exact
          component={FundingAccountsPage}
        />
        <SentryRoute
          path={`${path}/accounts-overview`}
          component={CustomerOverviewPage}
        />
        <SentryRoute
          path={`${path}/accounts/credit/:accountId/:view?`}
          exact
          component={CreditAccountPage}
        />
        <SentryRoute
          path={`${path}/applications/:applicationId`}
          exact
          component={CreditApplicationPage}
        />
        <SentryRoute
          path={`${path}/preferences`}
          exact
          component={PreferencesPage}
        />
        <SentryRoute path={`${path}/fraud`} component={FraudCasesPage} />
      </Switch>
      <Row
        gutter={[8, 8]}
        style={{
          marginTop: 'var(--spacing-xs)',
        }}
      >
        <Col md={24} lg={12}>
          <Interactions />
        </Col>
        <Col md={24} lg={12}>
          <AutoEvents />
        </Col>
      </Row>
    </>
  );
};

export default CustomerLayout;
