import { useParams } from 'react-router-dom';
import { Card, Col, Divider, Row } from 'antd';
import dayjs from 'dayjs';

import DisplayValue from 'components/DisplayValue/DisplayValue';
import QueryBoundary from 'components/QueryBoundary/QueryBoundary';
import useApplicationQuery from 'hooks/useApplicationQuery';
import {
  formatDateDescriptive,
  formatDateTimeDescriptive,
} from 'utilities/datesAndTimes';
import { SIZE } from 'utilities/constants';

const DECLINED_STATUS = 'DECLINED';

const DocumentURI = ({ document, ...rest }) => {
  document = document || {};
  const displayText = document?.name || 'View Document';
  return (
    <a
      target="_blank"
      rel="noreferrer"
      href={document.uri}
      disabled={!document.uri}
      {...rest}
    >
      {document.uri ? displayText : 'Not Found'}
    </a>
  );
};

const CreditApplicationPage = () => {
  const { applicationId } = useParams();
  const { application, documents, applicationQuery, bureauReports } =
    useApplicationQuery(applicationId);

  const renderSnapshotDocuments = () => {
    if (documents?.snapshots?.length) {
      return documents.snapshots.map((snapshot, index) => {
        const document = {
          uri: snapshot.uri,
          name: snapshot.snapshotType,
        };
        return (
          <div key={`snapshot-${index}`}>
            <DocumentURI
              document={document}
              data-testid={`snapshot-${index}`}
            />
          </div>
        );
      });
    }
    return <DocumentURI data-testid="snapshot-0" />;
  };

  const renderBureauReports = () => {
    if (bureauReports.length) {
      return bureauReports
        .slice()
        .sort((a, b) => dayjs(b.fetchedAt) - dayjs(a.fetchedAt))
        .map((report, index) => {
          return (
            <Row
              gutter={[24, 'var(--spacing-lg)']}
              key={`bureau-report-${index}`}
            >
              <Col md={24} lg={12}>
                <DisplayValue label="Bureau" size={SIZE.SM}>
                  {report.vendor}
                </DisplayValue>
              </Col>
              <Col md={24} lg={12}>
                <DisplayValue label="Timestamp" size={SIZE.SM}>
                  {report.fetchedAt}
                </DisplayValue>
              </Col>
              <Col md={24} lg={12}>
                <DisplayValue label="Pull Type" size={SIZE.SM}>
                  {report.pullType}
                </DisplayValue>
              </Col>
              <Col md={24} lg={12}>
                <DisplayValue label="Subscriber Code" size={SIZE.SM}>
                  {report.subscriberCode}
                </DisplayValue>
              </Col>
              {bureauReports.length > 1 &&
                index != bureauReports.length - 1 && <Divider />}
            </Row>
          );
        });
    }
  };

  return (
    <Card bordered={false}>
      <QueryBoundary query={applicationQuery}>
        <Card
          title={`Credit Application ${formatDateDescriptive(application.submittedAt)}`}
          type="inner"
        >
          <Row gutter={[24, 'var(--spacing-lg)']}>
            <Col md={24} lg={12}>
              <DisplayValue label="Submitted At" size={SIZE.SM}>
                {formatDateTimeDescriptive(application.submittedAt)}
              </DisplayValue>
            </Col>
            <Col md={24} lg={12}>
              <DisplayValue
                label="Application ID"
                size={SIZE.SM}
                canCopy={true}
              >
                {application.applicationId}
              </DisplayValue>
            </Col>
            <Col md={24} lg={12}>
              <DisplayValue label="Status" size={SIZE.SM}>
                {application.status}
              </DisplayValue>
            </Col>
            {application.status === DECLINED_STATUS && (
              <Col md={24} lg={12}>
                <DisplayValue label="Status Reason" size={SIZE.SM}>
                  {application.statusReason}
                </DisplayValue>
              </Col>
            )}
            <Col md={24} lg={12}>
              <DisplayValue label="Source" size={SIZE.SM}>
                {application.submission?.source}
              </DisplayValue>
            </Col>
            <Col md={24} lg={12}>
              <DisplayValue label="Disclosure Agreement" size={SIZE.SM}>
                <DocumentURI
                  data-testid="disclosure-agreement"
                  document={documents?.disclosure}
                />
              </DisplayValue>
            </Col>

            <Col md={24} lg={12}>
              <DisplayValue label="Cardholder Agreement (CAG)" size={SIZE.SM}>
                <DocumentURI
                  data-testid="cardholder-agreement"
                  document={documents?.cag}
                />
              </DisplayValue>
            </Col>

            <Col md={24} lg={12}>
              <DisplayValue label="Adverse Action Notice (AAN)" size={SIZE.SM}>
                <DocumentURI
                  data-testid="adverse-action-notice"
                  document={documents?.aan}
                />
              </DisplayValue>
            </Col>

            <Col md={24} lg={12}>
              <DisplayValue label="Snapshots" size={SIZE.SM}>
                {renderSnapshotDocuments()}
              </DisplayValue>
            </Col>
          </Row>
        </Card>
        {bureauReports.length ? (
          <Card
            title="Bureau Reports"
            type="inner"
            style={{ marginTop: 'var(--spacing-sm)' }}
          >
            {renderBureauReports()}
          </Card>
        ) : null}
      </QueryBoundary>
    </Card>
  );
};

export default CreditApplicationPage;
