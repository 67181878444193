import { useEffect } from 'react';
import { makeVar, useReactiveVar } from '@apollo/client';
import { Flex, Tag, Typography } from 'antd';

import { CUSTOMER_SEARCH_FIELD } from 'utilities/constants';

const SEARCH_FIELD_MAP = Object.values(CUSTOMER_SEARCH_FIELD).reduce(
  (map, field) => {
    map[field.name] = field;
    return map;
  },
  {},
);

const criteriaTagsVar = makeVar([]);

const initializeCriteriaTags = (criteria = []) => {
  const init = Object.keys(criteria).map((fieldName) => {
    return {
      label: SEARCH_FIELD_MAP[fieldName].label,
      value: criteria[fieldName],
      name: fieldName,
      checked: true,
    };
  });

  criteriaTagsVar(init);
};

const SearchCriteria = ({
  criteria,
  loading = false,
  onRemoveCriteria = () => {},
}) => {
  const criteriaTags = useReactiveVar(criteriaTagsVar);

  useEffect(() => {
    initializeCriteriaTags(criteria);
  }, [criteria]);

  return (
    <Flex
      gap="var(--spacing-md)"
      style={{
        borderBottom: '1px solid var(--border-light)',
        paddingBottom: 'var(--spacing-sm)',
      }}
    >
      <Typography.Text>Search Criteria:</Typography.Text>
      <Flex gap="var(--spacing-md)" flex="1">
        {criteriaTags.map((tag) => (
          <Tag
            key={`criteria-${tag.label}`}
            closeIcon={criteriaTags.length > 1}
            onClose={() => {
              !loading && onRemoveCriteria(tag.name);
            }}
          >
            {`${tag.label}: "${tag.value}"`}
          </Tag>
        ))}
      </Flex>
    </Flex>
  );
};

export default SearchCriteria;
