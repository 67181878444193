import { useReactiveVar } from '@apollo/client';
import dayjs from 'dayjs';

import { formatTime, getUserLocalTime } from 'utilities/datesAndTimes';
import { createReactiveState } from 'utilities/stateHelpers';
import { CUSTOMER_TYPE } from 'utilities/constants';

const convertCustomerToSearchResult = (customer) => {
  const customerResult = {
    key: `${customer.customerType}-${customer.customerId}}`,
    birtDate: customer.birthDate,
    customerId: customer.customerId,
    accountId: customer.accountId,
    email: customer.email,
    firstName: customer.firstName,
    ssn: customer.lastFourSsn,
    lastName: customer.lastName,
    state: customer.state,
    type: customer.customerType,
    products: [],
  };

  const customerNow = getUserLocalTime(customer.state);
  customerResult.localTime = dayjs(customerNow).isValid()
    ? formatTime(customerNow)
    : 'Unknown';

  return customerResult;
};

const customerSearchState = createReactiveState(() => {
  return {
    initialFieldValues: {},
    searchExecuted: false,
    searchCriteria: {},
    searchResults: [],
  };
});

export const setSearchExecuted =
  customerSearchState.createPropertySetter('searchExecuted');

export const setCustomerSearchCriteria =
  customerSearchState.createPropertySetter('searchCriteria');

// Refactor this once applicants have been fully transitioned to applications
export const setCustomerSearchResults = (results) => {
  const mappedResults = results.reduce((customerList, customer) => {
    let result = convertCustomerToSearchResult(customer);
    if (result.type === CUSTOMER_TYPE.CUSTOMER) {
      customerList.push(result);
    }
    return customerList;
  }, []);
  customerSearchState.setPropertyValue('searchResults', mappedResults);
};

export const clearCustomerSearchResults = () => {
  customerSearchState.setPropertyValue('searchResults', []);
};

export const useCustomerSearchState = () => {
  return useReactiveVar(customerSearchState.stateVar);
};

export default customerSearchState;
