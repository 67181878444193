import { Table } from 'antd';
import styled from '@emotion/styled';

import { COLORS } from 'utilities/styles';

// 605px is the height of a table with 10 rows. Just trying to keep a consistent height.
// There is probably a better way to do this but this seems to work ok for now.
const StyledTable = styled(Table)`
  .ant-table-container {
    min-height: 605px;
    background-color: ${COLORS.haze3};
    tbody {
      background-color: ${COLORS.haze4};
    }
  }
`;

const SearchResultsTable = ({ columns, data, loading, emptyText }) => {
  return (
    <StyledTable
      locale={{
        emptyText,
      }}
      columns={columns}
      dataSource={data}
      size="medium"
      loading={loading}
    />
  );
};

export default SearchResultsTable;
