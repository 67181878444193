import { Link } from 'react-router-dom';
import {
  Button,
  Flex,
  Tag,
  Dropdown,
  Layout,
  Menu,
  Space,
  Typography,
} from 'antd';
import { DownOutlined, PhoneFilled } from '@ant-design/icons';
import styled from '@emotion/styled';

import AddNotes from 'components/AddNotes/AddNotes';
import useAgent from 'hooks/useAgent';
import useAWSConnectInfo from 'hooks/useAWSConnectInfo';
import useCustomerInfo from 'hooks/useCustomerInfo';
import useModalControls from 'hooks/useModalControls';
import useNavigationKeyManager from 'hooks/useNavigationKeyManager';
import useSidebar from 'hooks/useSidebar';

import { COLORS } from 'utilities/styles';

import { useFlags } from 'launchdarkly-react-client-sdk';

const StyledHeader = styled(Layout.Header)`
  background-color: ${COLORS.haze4};
  box-shadow:
    0 1px 2px 0 rgba(0, 0, 0, 0.03),
    0 1px 6px -1px rgba(0, 0, 0, 0.02),
    0 2px 4px 0 rgba(0, 0, 0, 0.02);
  display: flex;
  height: var(--header-height);
  padding: 0 var(--spacing-lg);
  position: fixed;
  top: 0;
  z-index: 5;
  width: 100%;

  .ant-menu-title-content {
    a {
      display: block;
      height: 100%;
      color: inherit;
    }
  }
`;

const HeaderMenu = styled(Menu)`
  &.ant-menu-horizontal {
    background-color: ${COLORS.haze4};
    border-bottom: 0px;
    .ant-menu-title-content {
      display: block;
      background-color: ${COLORS.haze4};
      line-height: var(--header-height);
      vertical-align: middle;
    }
  }
`;

const getNavigationItems = (
  recentCustomers,
  recentApplications,
  isFdrComplaints,
) => {
  const customerSearchPath = '/customer-search';
  const customerNavigation = {
    key: 'customers',
    path: '/customers',
    label: <Link to={customerSearchPath}>Customers</Link>,
    children: [
      {
        label: <Link to={customerSearchPath}>Search Customers</Link>,
        key: 'customer-search',
        path: customerSearchPath,
      },
      {
        type: 'group',
        label: `Recently Viewed Customers`,
        children: recentCustomers.map((recentCustomer) => {
          const customerPath = `/customers/${recentCustomer.id}`;
          return {
            label: <Link to={customerPath}>{recentCustomer.label}</Link>,
            key: customerPath,
            path: customerPath,
          };
        }),
      },
    ],
  };

  const applicationSearchPath = '/application-search';
  const applicationNavigation = {
    key: 'applications',
    path: '/applications',
    label: <Link to={applicationSearchPath}>Applications</Link>,
    children: [
      {
        label: <Link to={applicationSearchPath}>Search Applications</Link>,
        key: 'application-search',
        path: applicationSearchPath,
      },
      {
        type: 'group',
        label: `Recently Viewed Applicants`,
        children: recentApplications.map((recentApplication) => {
          const applicationPath = `/applications/${recentApplication.id}`;
          return {
            label: <Link to={applicationPath}>{recentApplication.label}</Link>,
            key: applicationPath,
            path: applicationPath,
          };
        }),
      },
    ],
  };

  const navigationItems = [customerNavigation, applicationNavigation];

  if (isFdrComplaints) {
    navigationItems.push({
      label: <Link to="/fraud-files-search">Fraud Files Search</Link>,
      key: 'fraudFilesSearch',
      path: '/fraud-files-search',
      exact: 'true',
    });
  }

  return navigationItems;
};

const Header = () => {
  const bulkNoteModal = useModalControls();

  const agent = useAgent();
  const { sidebarOpen, toggleSidebarOpen } = useSidebar();
  const { recentCustomers, recentApplications } = useCustomerInfo();
  const { agentStatusColors } = useAWSConnectInfo();
  const { isFdrComplaints } = useFlags();

  const navigationItems = getNavigationItems(
    recentCustomers,
    recentApplications,
    isFdrComplaints,
  );

  const [activeRouteKey] = useNavigationKeyManager(navigationItems);

  const getUserBadge = () => {
    // Look int refactoring this. Dropdown is awkward here. Bulk notes should be moved to the notes pane.
    return agent.isSuperUser ? (
      <div>
        <Dropdown
          menu={{
            items: [
              {
                label: 'Add bulk note',
                onClick: bulkNoteModal.show,
              },
            ],
          }}
          trigger={['click']}
        >
          <Tag
            color={COLORS.blue1}
            style={{ cursor: 'pointer', marginRight: 0 }}
          >
            Super User
            <DownOutlined style={{ marginLeft: 'var(--spacing-xs)' }} />
          </Tag>
        </Dropdown>
      </div>
    ) : agent.isCommandManager ? (
      <Tag color={COLORS.green1}>Command Manager</Tag>
    ) : agent.hasAccess ? (
      <Tag color={COLORS.green1}>Agent</Tag>
    ) : (
      <Tag color={COLORS.red1}>No Access</Tag>
    );
  };

  return (
    <StyledHeader>
      <Flex
        align="center"
        style={{
          marginRight: 'var(--spacing-md)',
          width: 'var(--header-logo-width)',
        }}
      >
        <Typography.Title
          level={5}
          style={{ marginBottom: 0, lineHeight: 0.9, color: COLORS.purple1 }}
        >
          Mission
          <br /> Ctrl
        </Typography.Title>
      </Flex>

      <HeaderMenu
        mode="horizontal"
        theme="light"
        style={{ flexGrow: 2 }}
        selectedKeys={activeRouteKey}
        items={navigationItems}
      />

      <Flex gap="var(--spacing-md)" align="center">
        {getUserBadge()}
        <Space.Compact>
          <Button
            size="small"
            disabled
            style={{
              backgroundColor: agentStatusColors.background,
              borderColor: agentStatusColors.background,
              cursor: 'auto',
            }}
          >
            <PhoneFilled
              style={{
                fontSize: '1.2rem',
                color: agentStatusColors.foreground,
              }}
              color={agentStatusColors.foreground}
            />
          </Button>
          <Button onClick={toggleSidebarOpen} size="small">
            {sidebarOpen ? 'Hide' : 'Show'}
          </Button>
        </Space.Compact>
      </Flex>
      <AddNotes {...bulkNoteModal} />
    </StyledHeader>
  );
};

export default Header;
