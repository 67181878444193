import { useReactiveVar } from '@apollo/client';

import { createReactiveState } from 'utilities/stateHelpers';

export const convertFraudFileToSearchResult = (fraudFile, index) => {
  const fraudFileResult = {
    key: `${fraudFile.parentCaseId}_${fraudFile.caseId}_${index}`,
    customerName: fraudFile.customerName,
    parentCaseId: fraudFile.parentCaseId,
    caseId: fraudFile.caseId,
    signUrl: fraudFile.signUrl,
    documentName: fraudFile.documentName,
    fileType: fraudFile.fileType,
    maskedPanLast4: fraudFile.maskedPanLast4.slice(-4),
    accountId: fraudFile.accountId,
    fraudIndicator: fraudFile.fraudIndicator,
    clientId: fraudFile.clientId,
    vaultToken: fraudFile.vaultToken,
    statusWork: fraudFile.statusWork,
  };
  return fraudFileResult;
};

const fraudFilesSearchState = createReactiveState(() => {
  return {
    initialFieldValues: {},
    searchExecuted: false,
    searchCriteria: {},
    searchResults: [],
  };
});

export const setSearchExecuted =
  fraudFilesSearchState.createPropertySetter('searchExecuted');

export const setFraudFilesSearchResults = (results) => {
  const mappedResults = results.reduce((fraudFiles, fraudFile, index) => {
    fraudFiles.push(convertFraudFileToSearchResult(fraudFile, index));
    return fraudFiles;
  }, []);

  fraudFilesSearchState.setPropertyValue('searchResults', mappedResults);
};

export const clearFraudFilesSearchResults = () => {
  fraudFilesSearchState.setPropertyValue('searchResults', []);
};

export const useFraudFilesSearchState = () => {
  return useReactiveVar(fraudFilesSearchState.stateVar);
};

export default fraudFilesSearchState;
