import { useEffect, useMemo } from 'react';
import { Checkbox, Flex, Form, Modal, Typography } from 'antd';

import SectionMessage from 'components/SectionMessage/SectionMessage';
import { CUSTOMER_SEARCH_FIELD, SIZE, STATUS } from 'utilities/constants';
import useAgentSettings from 'hooks/useAgentSettings';
import useTimeoutValue from 'hooks/useTimeoutValue';

const CustomerSearchCheckbox = ({ field, width = '33.3%' }) => {
  return (
    <Form.Item
      style={{ flex: `0 0 ${width}`, marginBottom: 0 }}
      name={['defaultCustomerSearchFields', field.name]}
      valuePropName="checked"
    >
      <Checkbox>{field.label}</Checkbox>
    </Form.Item>
  );
};

const AgentSettingsModal = ({ ...modalProps }) => {
  const [agentSettingsForm] = Form.useForm();
  const [agentSettings, setAgentSettings] = useAgentSettings();
  const [settingsSaved, setSettingsSaved] = useTimeoutValue(false);

  const initialFormValues = useMemo(() => {
    let defaultCustomerSearchFields = {};

    defaultCustomerSearchFields = Object.values(CUSTOMER_SEARCH_FIELD).reduce(
      (acc, searchField) => {
        acc[searchField.name] =
          agentSettings.defaultCustomerSearchFields.includes(searchField.name);
        return acc;
      },
      {},
    );

    return {
      defaultCustomerSearchFields,
    };
  }, [agentSettings]);

  const saveSettings = (formValues) => {
    const defaultCustomerSearchFields = Object.keys(
      formValues.defaultCustomerSearchFields,
    ).reduce((defaultFields, fieldName) => {
      if (formValues.defaultCustomerSearchFields[fieldName]) {
        defaultFields.push(fieldName);
      }
      return defaultFields;
    }, []);

    setAgentSettings({
      defaultCustomerSearchFields,
    });

    setSettingsSaved(true);
  };

  useEffect(() => {
    agentSettingsForm.resetFields();
  }, [agentSettings]);

  return (
    <Modal
      {...modalProps}
      title="Configure Mission Control"
      onOk={agentSettingsForm.submit}
      okText="Save"
      width={800}
    >
      <div style={{ position: 'relative' }}>
        {settingsSaved ? (
          <SectionMessage
            cover={true}
            status={STATUS.SUCCESS}
            text="Settings Saved"
            size={SIZE.MD}
          />
        ) : null}
        <Form
          form={agentSettingsForm}
          initialValues={initialFormValues}
          onFinish={saveSettings}
        >
          <Typography.Title level={5}>
            Default Customer Search Fields
          </Typography.Title>
          <Flex wrap="wrap">
            <CustomerSearchCheckbox field={CUSTOMER_SEARCH_FIELD.FIRST_NAME} />
            <CustomerSearchCheckbox field={CUSTOMER_SEARCH_FIELD.LAST_NAME} />
            <CustomerSearchCheckbox field={CUSTOMER_SEARCH_FIELD.BIRTH_DATE} />

            <CustomerSearchCheckbox
              field={CUSTOMER_SEARCH_FIELD.PHONE_NUMBER}
            />
            <CustomerSearchCheckbox field={CUSTOMER_SEARCH_FIELD.EMAIL} />
            <CustomerSearchCheckbox field={CUSTOMER_SEARCH_FIELD.CARD_LAST_4} />

            <CustomerSearchCheckbox
              field={CUSTOMER_SEARCH_FIELD.EXTERNAL_ACCOUNT_ID}
            />
            <CustomerSearchCheckbox field={CUSTOMER_SEARCH_FIELD.CUSTOMER_ID} />
            <CustomerSearchCheckbox field={CUSTOMER_SEARCH_FIELD.ACCOUNT_ID} />

            <CustomerSearchCheckbox field={CUSTOMER_SEARCH_FIELD.LAST_4_SSN} />
            <CustomerSearchCheckbox
              field={CUSTOMER_SEARCH_FIELD.FULL_SSN}
              width="66.6%"
            />

            <CustomerSearchCheckbox field={CUSTOMER_SEARCH_FIELD.STREET_1} />
            <CustomerSearchCheckbox
              field={CUSTOMER_SEARCH_FIELD.STREET_2}
              width="66.6%"
            />
            <CustomerSearchCheckbox field={CUSTOMER_SEARCH_FIELD.CITY} />
            <CustomerSearchCheckbox
              field={CUSTOMER_SEARCH_FIELD.STATE}
              width="66.6%"
            />
          </Flex>
        </Form>
      </div>
    </Modal>
  );
};

export default AgentSettingsModal;
